import LazyLoad from "vanilla-lazyload";

let __svg__ = {path: '../../resources/svg/**/*.svg', name: 'svg/sprite.svg'};

new LazyLoad({elements_selector: ".lazy"});

if (document.querySelector('.show-close-mm')) {

  document.querySelectorAll('.show-close-mm').forEach(button => {
    button.addEventListener('click', function () {
      let mobileMenu = document.getElementById('mm');
      let menuHamburger = document.getElementById('menu-hamburger');

      menuHamburger.classList.toggle('active');
      mobileMenu.classList.toggle('active');
    });
  });
}

if (document.querySelector('.dropdown__title')) {
  function showHideDropdown(dropdown) {
    let dropdownContent = document.querySelector('.dropdown__content');

    if (dropdown.classList.contains('active')) {
      dropdown.classList.remove('active');
      document.removeEventListener('click', windowClickListener);
      setTimeout(() => {
        dropdownContent.style.display = 'none';
      }, 200);
    } else {
      dropdownContent.style.display = 'block';
      document.addEventListener('click', windowClickListener);
      setTimeout(() => {
        dropdown.classList.add('active');
      }, 15);
    }
  }

  document.querySelectorAll('.dropdown__title').forEach(dropdownTitle => {
    dropdownTitle.addEventListener('click', (e) => {
      let dropdown = dropdownTitle.parentElement;

      showHideDropdown(dropdown);
    });
  });

  let windowClickListener = function (e) {
    let activeDropdown = document.querySelector('.dropdown.active');

    if (activeDropdown) {
      let activeDropdownContent = activeDropdown.querySelector('.dropdown__content');

      if (e.target !== activeDropdownContent) {
        showHideDropdown(activeDropdown);
      }
    }
  };
}

if (document.getElementById('experts-slider')) {
  let headerSlider = tns({
    container: '#experts-slider',
    loop: false,
    rewind: true,
    mouseDrag: false,
    preventScrollOnTouch: true,
    nav: false,
    gutter: 32,
    items: 1,
    autoHeight: true,
    controlsContainer: document.getElementById('experts-slider-controls'),
    responsive: {580: {items: 1}}
  });
}

if (document.getElementById('hero-slider')) {
  let heroSlider = tns({
    container: '#hero-slider',
    loop: false,
    rewind: true,
    mouseDrag: true,
    preventScrollOnTouch: true,
    nav: false,
    autoplay: true,
    autoplayTimeout:4000,
    gutter: 0,
    items: 1,
    autoHeight: false,
    controls:false,
    controlsContainer: document.getElementById('hero-slider-controls'),
  });
}

if (document.querySelector('.js--persons-slider')) {
  let sliderNodes = document.querySelectorAll('.js--persons-slider');
  let aboutButtons = document.querySelectorAll('.persons-slider__about');

  sliderNodes.forEach(sliderNode => {
    let sliderId = sliderNode.id;

    tns({
      container: sliderNode,
      loop: false,
      rewind: true,
      mouseDrag: false,
      preventScrollOnTouch: true,
      nav: false,
      gutter: 16,
      items: 2,
      autoHeight: true,
      controlsContainer: document.getElementById(sliderId + '-controls'),
      responsive: {480: {items: 3}, 640: {items: 4, gutter: 16}, 992: {items: 4}, 1200: {items: 5}}
    });

    if (aboutButtons) {
      aboutButtons.forEach(button => {
        button.addEventListener('click', () => {
          let description = button.parentElement.nextElementSibling;

          if (button.classList.contains('active')) {
            button.classList.remove('active');
            description.classList.remove('active');
            description.style.maxHeight = "0px";
          } else {
            button.classList.add('active');
            description.classList.add('active');
            description.style.maxHeight = description.scrollHeight + "px";
          }
        });
      });
    }

  });
}

function closePopup(popup) {
  popup.classList.add('disabled');
  popup.classList.remove('active');
  setTimeout(function () {
    popup.style.display = 'none';
    popup.classList.remove('disabled');
  }, 300);
}

function showPopup(popup) {
  if (popup && !popup.classList.contains('disabled')) {
    popup.classList.add('disabled');
    popup.style.display = 'flex';
    setTimeout(function () {
      popup.classList.add('active');
    }, 15);
    setTimeout(function () {
      popup.classList.remove('disabled');
    }, 300);
  }
}

document.querySelectorAll('.show-popup').forEach(button => {
  button.addEventListener('click', function () {
    let popupId = button.dataset.popupId;
    let popup = document.getElementById(popupId);

    document.querySelectorAll('.popup__wrapper').forEach(item => popup !== item ? closePopup(item) : null);

    showPopup(popup);
  });
});

document.querySelectorAll('.popup__close').forEach(button => {
  button.addEventListener('click', function () {
    let popup = button.parentElement.parentElement;

    if (popup) {
      closePopup(popup);
    }
  });
});

document.querySelectorAll('.popup__wrapper').forEach(wrapper => {
  wrapper.addEventListener('click', function () {
    if (!wrapper.classList.contains('disabled')) {
      closePopup(wrapper);
    }
  });
});

document.querySelectorAll('.popup').forEach(popup => {
  popup.addEventListener('click', e => {
    e.stopPropagation();
  });
});
